<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-search-box @enter="getList">
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- 사업장 -->
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-dept
              :isFirstValue="false"
              label="부서"
              name="deptCd"
              v-model="searchParam.deptCd"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :range="true"
              label="작업기간"
              defaultStart="-14d"
              defaultEnd="0d"
              name="period"
              v-model="searchParam.period"
            />
          </div>
        </template>
      </c-search-box>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
      <c-table
        ref="table"
        title="이행여부 현황 목록"
        tableId="table"
        :columns="grid.columns"
        :merge="grid.merge"
        :data="grid.data"
        @linkClick="linkClick"
      >
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
    <c-card title="무재해 대상 부서별 건 수" class="cardClassDetailForm bodyOverflow-none">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-12">
            <apexchart 
              ref="chart" 
              :height="chartHeight" 
              type="bar"
              :width="chart.chartWidth"
              :options="chart.chartOptions" 
              :series="chart.series"></apexchart>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'tbm-fulfillment',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: '',
        userId: '',
        period: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'deptCd' },
        ],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            style: 'width:130px',
            label: '무재해 대상 부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'tbmWorkDate',
            field: 'tbmWorkDate',
            label: '작업일',
            style: 'width:100px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'tbmName',
            field: 'tbmName',
            label: 'TBM명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: false,
          },
          {
            name: 'permitNo',
            field: 'permitNo',
            label: '작업허가번호',
            style: 'width:120px',
            align: 'center',
            type: 'link',
            sortable: false,
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            label: '허가서구분',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'workTime',
            field: 'workTime',
            label: '작업시간',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
        ],
        data: [],
      },
      chart: {
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
          },
          plotOptions: {
            bar: {
              barHeight: '100%',
              distributed: true,
              horizontal: true,
              dataLabels: {
                position: 'bottom'
              },
            }
          },
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
              colors: ['#fff']
            },
            formatter: function (val, opt) {
              return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + '건'
            },
            offsetX: 0,
            dropShadow: {
              enabled: true
            }
          },
          colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
          ],
          // colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          stroke: {
            width: 1,
            colors: ['#fff']
          },
          // labels: {
          //   style: {
          //     colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          //     fontSize: '12px'
          //   }
          // },
          title: {
            text: ''
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            labels: {
              show: false
            }
          },
          tooltip: {
            x: {
              show: false
            },
            y: {
              formatter: function (val) {
                return val + "건"
              }
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
          }
        },
        series: [
          {
            name: '건 수',
            data: [],
          },
        ],
        chartWidth: '100%',
      },
      listUrl: '',
      graphListUrl: '',
      saveable: false,
      chartHeight: '',
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        // isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      // api scope
      this.listUrl = selectConfig.sai.tbm.fulfillment.url;
      this.graphListUrl = selectConfig.sai.tbm.fulfillmentGraph.url;
      this.getList();
      this.setSize();
    },
    getList() {
      this.getTableList();
      this.getGraphList();
    },
    getTableList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getGraphList() {
      this.$http.url = this.graphListUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.chart.chartOptions.xaxis.categories = this.$_.map(_result.data, 'deptName');
        this.$set(this.chart.series[0], 'data', this.$_.map(_result.data, 'deptCount'))
        this.$refs['chart'].refresh();
      },);
    },
    linkClick(result, col) {
      if (col && col.name === 'permitNo') {
        this.popupOptions.target = () =>
        import(`${"@/pages/sop/swp/safeWorkPermitDetail.vue"}`);
        this.popupOptions.title = '작업허가서 상세';
        this.popupOptions.param = {
          sopWorkPermitId: result ? result.sopWorkPermitId : '',
          permitTypeCd: result ? result.permitTypeCd : '',
        };
      } else {
        this.popupOptions.target = () =>
        import(`${"./tbmDetail.vue"}`);
        this.popupOptions.title = 'TBM 상세';
        this.popupOptions.param = {
          tbmId: result ? result.tbmId : '',
        };
      }
      this.popupOptions.isFull = true;
      // this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getTableList();
    },
    setSize() {
      let height = window.innerHeight - 290;
      height = height >= 400 ? height : 400
      this.chartHeight = height;
    },
  }
};
</script>
